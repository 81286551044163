import {
  TypesActionsGeneralSale,
  LIST_GENERAL_SALE,
  SHOW_GENERAL_SALE,
  UPDATE_GENERAL_SALE,
  DELETE_GENERAL_SALE,
  GET_GENERAL_SALES,
  RESET_GENERAL_SALES_STATE,
} from '../../actions/generalSale/GeneralSaleTypes';
import { GeneralSaleState } from '../../models/generalSale/GeneralSaleState';
import { GeneralSaleValues } from '../../../types/generalSales/interfaces';
import { generalResponse } from '../../../types/common/interfaces';

const initialState: GeneralSaleState = {
  generalSales: { ...generalResponse, totalAmount: 0 },
  generalSale: GeneralSaleValues,
  generalSalesList: { ...generalResponse, totalAmount: 0 },
};

export default function generalSaleReducer(
  state = initialState,
  action: TypesActionsGeneralSale
) {
  switch (action.type) {
    case LIST_GENERAL_SALE: {
      const generalSales = action.payload;
      return {
        ...state,
        generalSales,
      };
    }
    case GET_GENERAL_SALES: {
      const generalSalesList = action.payload;
      return {
        ...state,
        generalSalesList,
      };
    }
    case SHOW_GENERAL_SALE: {
      const generalSale = action.payload;
      return {
        ...state,
        generalSale,
      };
    }
    case UPDATE_GENERAL_SALE: {
      const generalSale = action.payload;
      const index = state.generalSales.docs.findIndex(
        (todo) => todo._id === generalSale._id
      );
      const newArray = [...state.generalSales.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.generalSales;
      const generalSales = {
        docs: newArray,
        ...rest,
      };
      return {
        ...state,
        generalSales,
      };
    }
    case DELETE_GENERAL_SALE: {
      const id = action.payload;
      return {
        ...state,
        generalSales: {
          docs: [...state.generalSales.docs.filter((e) => e._id !== id)],
        },
      };
    }
    case RESET_GENERAL_SALES_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
