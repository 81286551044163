import * as Yup from 'yup';

import { UserAuth } from '../users/User';
import { ResponseProvider } from '../provider/Provider';
import { AccountSimplifiedPlanValues } from '../accounts/interfaces';
import { ProviderInitialState } from '../provider/interfaces';
import {
  GeneralPurchase,
  ResponseGeneralPurchase,
  GeneralPurchaseProduct,
} from './GeneralPurchase';

export interface GeneralShoppingScreenProps {
  user: UserAuth;
  generalPurchases: ResponseGeneralPurchase;
  listGeneralPurchase: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  deleteGeneralPurchase: (
    form: {
      id: string;
      accountId: string;
    },
    type: string,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
}

export interface CreateGeneralShoppingProps {
  user: UserAuth;
  createGeneralPurchase: (
    form: object,
    callbackSuccess: (generalPurchase: GeneralPurchase) => void,
    callbackError: Function
  ) => Promise<void>;
  providers: ResponseProvider;
  listProvider: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
}

export interface EditGeneralShoppingProps {
  user: UserAuth;
  updateGeneralPurchase: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  getGeneralPurchase: (
    id: string,
    accountId: string,
    callback: Function
  ) => Promise<void>;
  generalPurchase: GeneralPurchase;
  providers: ResponseProvider;
  listProvider: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
}

export interface ProductFormValues extends GeneralPurchaseProduct {}

export interface GeneralShoppingFormValues {
  accountId?: string;
  amount: number;
  observations: string;
  products: ProductFormValues[];
  id?: string;
  _id?: string;
  providerId?: string;
  date: string;
  reference?: string;
}

export const initialValues: GeneralShoppingFormValues = {
  accountId: '',
  amount: 0,
  observations: '',
  products: [],
  providerId: '',
  date: '',
  reference: '',
};

export const validationSchema: Yup.SchemaOf<GeneralShoppingFormValues> =
  Yup.object({
    accountId: Yup.string(),
    amount: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),
    observations: Yup.string(),
    products: Yup.array(
      Yup.object().shape({
        name: Yup.string(),
        qty: Yup.number(),
        unitPrice: Yup.number(),
        total: Yup.number(),
        notes: Yup.string(),
      })
    ).min(1, 'Se requiere algregar al menos un producto'),
    id: Yup.string(),
    providerId: Yup.string().nullable(),
    date: Yup.string().required('Este campo es requerido'),
    reference: Yup.string(),
    _id: Yup.string(),
  });

export const initialValuesProduct: ProductFormValues = {
  name: '',
  qty: 1,
  unitPrice: 0,
  total: 0,
  notes: '',
};

export const validationSchemaProduct: Yup.SchemaOf<ProductFormValues> =
  Yup.object({
    name: Yup.string().required('Este campo es requerido'),
    qty: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),
    unitPrice: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),
    total: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),
    notes: Yup.string(),
  });

export const GeneralPurchaseValues: GeneralPurchase = {
  _id: '',
  accountId: AccountSimplifiedPlanValues,
  providerId: ProviderInitialState,
  amount: 0,
  observations: '',
  date: '',
  reference: '',
  products: [],
  createdAt: '',
  deletedAt: '',
};
