import {
  TypesActionsGeneralPurchase,
  LIST_GENERAL_PURCHASE,
  SHOW_GENERAL_PURCHASE,
  UPDATE_GENERAL_PURCHASE,
  DELETE_GENERAL_PURCHASE,
  GET_GENERAL_PURCHASES,
  RESET_GENERAL_PURCHASES_STATE,
} from '../../actions/generalPurchase/GeneralPurchaseTypes';
import { GeneralPurchaseState } from '../../models/generalPurchase/GeneralPurchaseState';
import { GeneralPurchaseValues } from '../../../types/generalPurchase/interface';
import { generalResponse } from '../../../types/common/interfaces';

const initialState: GeneralPurchaseState = {
  generalPurchases: { ...generalResponse, totalAmount: 0 },
  generalPurchase: GeneralPurchaseValues,
  generalPurchasesList: { ...generalResponse, totalAmount: 0 },
};

export default function generalPurchaseReducer(
  state = initialState,
  action: TypesActionsGeneralPurchase
) {
  switch (action.type) {
    case LIST_GENERAL_PURCHASE: {
      const generalPurchases = action.payload;
      return {
        ...state,
        generalPurchases,
      };
    }
    case GET_GENERAL_PURCHASES: {
      const generalPurchasesList = action.payload;
      return {
        ...state,
        generalPurchasesList,
      };
    }
    case SHOW_GENERAL_PURCHASE: {
      const generalPurchase = action.payload;
      return {
        ...state,
        generalPurchase,
      };
    }
    case UPDATE_GENERAL_PURCHASE: {
      const generalPurchase = action.payload;
      const index = state.generalPurchases.docs.findIndex(
        (todo) => todo._id === generalPurchase._id
      );
      const newArray = [...state.generalPurchases.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.generalPurchases;
      const generalPurchases = {
        docs: newArray,
        ...rest,
      };
      return {
        ...state,
        generalPurchases,
      };
    }
    case DELETE_GENERAL_PURCHASE: {
      const id = action.payload;
      return {
        ...state,
        generalPurchases: {
          docs: [...state.generalPurchases.docs.filter((e) => e._id !== id)],
        },
      };
    }
    case RESET_GENERAL_PURCHASES_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
