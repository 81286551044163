import { combineReducers } from 'redux';
import userReducer from './user/userReducer';
import foodCategoriesReducer from './foodCategories/foodCategoriesReducer';
import accountsReducer from './accounts/accountsReducer';
import foodComplementsReducer from './foodComplements/foodComplementsReducer';
import foodsReducer from './foods/foodsReducer';
import ordersReducer from './orders/orderReducer';
import dashboardReducer from './dashboard/dashboardReducer';
import invoicesReducer from './invoices/invoiceReducer';
import tablesReducer from './tables/tableReducer';
import cashRegisterReducer from './cashRegister/cashRegisterReducer';
import cashRegisterMovementReducer from './cashRegisterMovement/cashRegisterMovementReducer';
import warehouseReducer from './warehouse/warehouseReducer';
import providerReducer from './provider/providerReducer';
import recipeReducer from './recipes/recipeReducer';
import inventoryReducer from './inventory/inventoryReducer';
import decreaseReducer from './decreases/decreaseReducer';
import purchaseReducer from './purchase/purchaseReducer';
import salesReducer from './sales/salesReducer';
import accountingPurchasesReducer from './accountingPurchase/accountingPurchaseReducer';
import payrollReducer from './payrolls/payrollReducer';
import usersReducer from './users/usersReducer';
import generalPurchaseReducer from './generalPurchase/generalPurchaseReducer';
import generalSaleReducer from './generalSale/generalSaleReducer';

const rootReducer = combineReducers({
  user: userReducer,
  foodCategories: foodCategoriesReducer,
  accounts: accountsReducer,
  foodComplements: foodComplementsReducer,
  foods: foodsReducer,
  orders: ordersReducer,
  dashboard: dashboardReducer,
  invoices: invoicesReducer,
  tables: tablesReducer,
  cashRegisters: cashRegisterReducer,
  cashRegisterMovements: cashRegisterMovementReducer,
  warehouses: warehouseReducer,
  providers: providerReducer,
  recipes: recipeReducer,
  inventories: inventoryReducer,
  decreases: decreaseReducer,
  purchases: purchaseReducer,
  sales: salesReducer,
  accountingPurchases: accountingPurchasesReducer,
  payrolls: payrollReducer,
  users: usersReducer,
  generalPurchases: generalPurchaseReducer,
  generalSales: generalSaleReducer,
});

export default rootReducer;
