import {
  GeneralSale,
  ResponseGeneralSale,
} from '../../../types/generalSales/GeneralSales';

export const LIST_GENERAL_SALE = 'LIST_GENERAL_SALE';
export const SHOW_GENERAL_SALE = 'SHOW_GENERAL_SALE';
export const UPDATE_GENERAL_SALE = 'UPDATE_GENERAL_SALE';
export const DELETE_GENERAL_SALE = 'DELETE_GENERAL_SALE';
export const GET_GENERAL_SALES = 'GET_GENERAL_SALES';
export const RESET_GENERAL_SALES_STATE = 'RESET_GENERAL_SALES_STATE';

interface ActionListGeneralSale {
  type: typeof LIST_GENERAL_SALE;
  payload: ResponseGeneralSale;
}

interface ActionShowGeneralSale {
  type: typeof SHOW_GENERAL_SALE;
  payload: GeneralSale;
}

interface ActionUpdateGeneralSale {
  type: typeof UPDATE_GENERAL_SALE;
  payload: GeneralSale;
}

interface ActionDeleteGeneralSale {
  type: typeof DELETE_GENERAL_SALE;
  payload: string;
}

interface ActionGetGeneralSales {
  type: typeof GET_GENERAL_SALES;
  payload: ResponseGeneralSale;
}

export interface ActionResetGeneralSaleState {
  type: typeof RESET_GENERAL_SALES_STATE;
}

export type TypesActionsGeneralSale =
  | ActionListGeneralSale
  | ActionShowGeneralSale
  | ActionUpdateGeneralSale
  | ActionDeleteGeneralSale
  | ActionGetGeneralSales
  | ActionResetGeneralSaleState;
