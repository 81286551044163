import {
  GeneralPurchase,
  ResponseGeneralPurchase,
} from '../../../types/generalPurchase/GeneralPurchase';

export const LIST_GENERAL_PURCHASE = 'LIST_GENERAL_PURCHASE';
export const SHOW_GENERAL_PURCHASE = 'SHOW_GENERAL_PURCHASE';
export const UPDATE_GENERAL_PURCHASE = 'UPDATE_GENERAL_PURCHASE';
export const DELETE_GENERAL_PURCHASE = 'DELETE_GENERAL_PURCHASE';
export const GET_GENERAL_PURCHASES = 'GET_GENERAL_PURCHASES';
export const RESET_GENERAL_PURCHASES_STATE = 'RESET_GENERAL_PURCHASES_STATE';

interface ActionListGeneralPurchase {
  type: typeof LIST_GENERAL_PURCHASE;
  payload: ResponseGeneralPurchase;
}

interface ActionShowGeneralPurchase {
  type: typeof SHOW_GENERAL_PURCHASE;
  payload: GeneralPurchase;
}

interface ActionUpdateGeneralPurchase {
  type: typeof UPDATE_GENERAL_PURCHASE;
  payload: GeneralPurchase;
}

interface ActionDeleteGeneralPurchase {
  type: typeof DELETE_GENERAL_PURCHASE;
  payload: string;
}

interface ActionGetGeneralPurchases {
  type: typeof GET_GENERAL_PURCHASES;
  payload: ResponseGeneralPurchase;
}

export interface ActionResetGeneralPurchaseState {
  type: typeof RESET_GENERAL_PURCHASES_STATE;
}

export type TypesActionsGeneralPurchase =
  | ActionListGeneralPurchase
  | ActionShowGeneralPurchase
  | ActionUpdateGeneralPurchase
  | ActionDeleteGeneralPurchase
  | ActionGetGeneralPurchases
  | ActionResetGeneralPurchaseState;
